import { graphql } from "gatsby";
import React from "react";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Post from "../components/Post";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query PostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      publishedAt(formatString: "DD MMM YY")
      id
      slug {
        current
      }
      mainImage {
        asset {
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawSocialImage
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        author {
          _rawImage
          name
          id
          twitterHandle
        }
      }
      title
      subtitle
    }
  }
`;

const PostTemplate = props => {
  const { data, errors } = props;
  const post = data && data.post;
  return (
    <Layout>
      {errors && <SEO title="GraphQL  Error" />}
      {post && (
        <SEO
          title={`${post.title}`}
          description={toPlainText(post._rawExcerpt)}
          image={post._rawSocialImage ? post._rawSocialImage : post._rawMainImage || null}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {post && <Post {...post} {...props} />}
    </Layout>
  );
};

export default PostTemplate;
